import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import * as navLinkStyles from "./navLink.module.css"

const NavLink = ({ children, to }) => (
  <Link
    to={to}
    className={navLinkStyles.base}
    activeClassName={navLinkStyles.active}
  >
    {children}
  </Link>
)

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default NavLink
