import React from "react"
import PropTypes from "prop-types"

import * as separatorStyles from "./separator.module.css"

const Separator = ({ content }) => (
  <div className={separatorStyles.parent}>
    <span>{content}</span>
  </div>
)

Separator.propTypes = {
  content: PropTypes.string.isRequired,
}

export default Separator
