import React from "react"
import { Link } from "gatsby"

import * as headerStyles from "./header.module.css"
import NavLink from "./navLink"
import Separator from "./separator"

const Header = () => {
  return (
    <header className={headerStyles.header}>
      <div className="container-md">
        <div className={headerStyles.outerFrame}>
          <div className={headerStyles.innerFrame}>
            <div className={headerStyles.brandContainer}>
              <Link to="/" className={headerStyles.plainLink} tabIndex="-1">
                <h1 className={headerStyles.title}>
                  Parties Unlimited
                  <span className={headerStyles.titleSub}>
                    <small>
                      <Separator content="and" />
                    </small>
                    Event Rentals
                  </span>
                </h1>
              </Link>
            </div>
            <address className={headerStyles.contactContainer}>
              <span>413 Valley St, Burlington, IA</span>
              <span>
                <a className={headerStyles.plainLink} href="tel:+13195761176">
                  319.576.1176
                </a>
              </span>
              <span>T-F: 10a-5p Sat: 10a-3p</span>
            </address>
          </div>
        </div>
      </div>

      <div className={headerStyles.links}>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/rentals">Rentals</NavLink>
        <NavLink to="/linens">Linens</NavLink>
        <NavLink to="/tuxedos">Tuxedos</NavLink>
        <NavLink to="/balloons">Balloons</NavLink>
        <NavLink to="/partySupplies">Party Supplies</NavLink>
      </div>
    </header>
  )
}

export default Header
