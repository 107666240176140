/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import News from "./news"
import Header from "./header"
import Footer from "./footer"

import ConfettiGenerator from "confetti-js"

import * as layoutStyles from "./layout.module.css"

const Layout = ({ children }) => {
  React.useEffect(() => {
    // Don't show effect if LocalStorage isn't supported
    if (!localStorage) {
      return
    }

    // Track last confetti
    const now = Date.now()
    const lastConfetti = localStorage.getItem("lastConfetti")

    const isFirstVisit = lastConfetti == null
    const fiveMinutesElapsed = () => (now - lastConfetti) / (1000 * 60) >= 5
    const oneDayElapsed = () =>
      (now - lastConfetti) / (1000 * 60 * 60 * 24) >= 1

    // 100% change of showing confetti if a day has passed
    // 20% chance of showing confetti if 5 minutes visits and
    if (
      isFirstVisit ||
      oneDayElapsed() ||
      (fiveMinutesElapsed() && Math.random() < 0.2)
    ) {
      localStorage.setItem("lastConfetti", Date.now())
    } else {
      return
    }

    const confettiSettings = {
      target: layoutStyles.confetti,
      max: 100,
      size: 1.5,
      animate: true,
      props: ["circle", "square", "triangle", "line"],
      colors: [
        [165, 94, 234],
        [235, 59, 90],
        [69, 170, 242],
        [254, 211, 48],
      ],
      clock: 100,
      rotate: true,
      start_from_edge: true,
      respawn: false,
    }
    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()

    return () => confetti.clear()
  })

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id={layoutStyles.root} className="d-flex flex-column">
      <canvas id={layoutStyles.confetti}></canvas>
      <News />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className={`${layoutStyles.main} container`}>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
