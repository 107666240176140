import React from "react"

import * as newsStyles from "./news.module.css"

const News = () => {
  return (
    <aside className={newsStyles.main}>
      We're open! 🥳 Come see our new location!
    </aside>
  )
}

export default News
