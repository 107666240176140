import React from "react"

import * as footerStyles from "./footer.module.css"

const Footer = () => (
  <footer className={`${footerStyles.footer} muted container`}>
    <span>
      <a href="mailto:mypartiesunlimited@yahoo.com">
        mypartiesunlimited@yahoo.com
      </a>
    </span>
    <span>
      <a
        href="https://www.facebook.com/partiesunlimitedburlingtoniowa"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/facebook_icon.svg"
          alt="Facebook icon"
          className={footerStyles.icon}
        />
      </a>
      <a
        href="https://www.instagram.com/mypartiesunlimited/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/instagram_icon.svg"
          alt="Instagram icon"
          className={footerStyles.icon}
        />
      </a>
    </span>
  </footer>
)

export default Footer
